@use "sass:math";
@import '../../../assets/stylesheets/_utils';

.classrooms {
  position: relative;
  display: flex;
  padding-bottom: 16px;

  &::after {
    width: 30px;
    flex-shrink: 0;
    content: '';
  }
}

.classroom {
  display: block;
  position: relative;
  text-decoration: none;
  width: 300px;
  margin-right: 30px;
  flex-shrink: 0;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    margin-right: 20px;
  }

  &:hover {
    .classroomThumbnail {
      filter: brightness(1.1);
    }
  }

  .classroomBox {
    position: relative;
    height: 230px;
  }

  .classroomThumbnail {
    position: relative;
    height: 100%;
    margin-bottom: 8px;
    @include imageBackground;
    border-radius: 15px 15px 80px 15px;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property: filter;
    object-fit: cover;
  }

  .classroomName {
    @include fontWeightBold;
    letter-spacing: 0;
    margin-top: 8px;
  }

  .classroomDescriptionBox {
    overflow: hidden;
  }

  .classroomDescription {
    margin-top: 8px;
    @include fontSmall;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .live {
    display: flex;
    align-items: center;
    position: absolute;
    top: 13px;
    left: 13px;
    padding: 2px 7px 1px;
    background: $colorRed;
    color: white;
    border-radius: 4px;

    p {
      position: relative;
      top: -1px;
      min-height: auto;
      margin: 0 0 0 4px;
      @include fontRotunda;
      @include fontSubMediumHeading;
    }
  }

  .schedule {
    position: absolute;
    top: 14px;
    left: 13px;
    p {
      @include fontRotunda;
      @include fontWeightBold;
      color: white;
      line-height: 1;
      min-height: auto;
      text-shadow: 0 0 5px rgba(#000, 0.5
      );
    }
  }

  .check {
    position: absolute;
    bottom: 0;
    right: 7px;
  }
}

.userInfoBox {
  .userInfoText {
    position: absolute;
    left: 0;
    bottom: 18px;
    right: 0;
    padding: 6px 16px 9px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 2.07%, rgba(0, 0, 0, 0.75) 100%);
  }

  .userInfoThumbnail {
    position: absolute;
    bottom: 13px;
    right: 15px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    @include imageBackground;
  }

  .userStatus {
    display: inline-block;
    padding: 4px 3px;
    font-size: 10px;
    color: #fff;
    line-height: 1;
    background: $colorGreen;
    @include fontWeightBold;
    border-radius: 4px;
  }

  .userName {
    width: 149px;
    margin-bottom: -3px;
    @include fontWeightBold;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .userLength {
    margin: 0;
    @include fontSmall;
    color: white;
    min-height: auto;
  }
}
