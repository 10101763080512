@import '../../../assets/stylesheets/_utils';


.section {
  padding: 0 0 $p4;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    padding-bottom: $p2;
  }

  &:first-child {
    padding-top: 0;
  }

  .inner {
    max-width: 640px;

    /* SM under */
    @media screen and (max-width: $screen-sm-max) {
      max-width: inherit;
    }
  }
  .title {
    @include fontHeading;
    @include fontText;
    @include fontWeightBold;
    @include font(24px, 5vw, 1.2);
    margin-bottom: $p2;
    padding-bottom: $p;
    display: flex;
    align-items: baseline;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $p;
    }
  }
  .border {
    border-bottom: 1px solid $colorStrokeContent;

    &.colorWhite {
      border-bottom: 1px solid $colorWhite;
    }
  }

  .space {
    display: flex;
    justify-content: space-between;
  }
}
