@import '../../../../assets/stylesheets/_utils';

.card {
  position: relative;
  width: 180px;
  height: 247px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  @include transition(border);

  @include hover() {
    border: 1px solid $colorFillLinkText;
  }

  @media screen and (max-width: 800px + $p2) {
    flex-grow: 1;
    // 164pxを超えたらiPhone Se 2の表示が崩れます
    width: 162px;
  }

  * {
    white-space: nowrap;
    text-decoration: none !important;
  }
}

.cartContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.avatar {
  @include imageBackground;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.professionalTitle {
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  padding: 0 16px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  color: #222222;
}

.archiveCount {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.3);
}

.name {
  font-weight: 700;
  font-size: 16px;
  color: #222222;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  position: relative;
  padding: 0 16px;
  text-align: center;
}

.scheduledClassroomCount {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: rgb(255, 68, 68);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colorWhite;
  font-size: 14px;
  font-weight: bold;

  position: absolute;
  left: 8px;
  top: 8px;
}
