@mixin kern {
  font-feature-settings : "palt" 1;
  letter-spacing: 0.02em;
}

@mixin fontText {
  font-family: YakuHanJP, "Rotunda", 'Noto Sans JP', 'Noto Sans CJK JP', noto-sans-cjk-jp, YuGothic, '游ゴシック', "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
@mixin fontHeading {
  font-family: YakuHanJP, "Rotunda", 'Noto Sans JP', 'Noto Sans CJK JP', noto-sans-cjk-jp, YuGothic, '游ゴシック', "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  font-style: normal;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

@mixin fontRotunda {
  font-family: YakuHanJP, "Rotunda", 'Noto Sans JP', 'Noto Sans CJK JP', noto-sans-cjk-jp, YuGothic, '游ゴシック', "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  font-style: normal;
  letter-spacing: 0em;
  line-height: 1.2;
}

@mixin fontWeightNormal {
  font-weight: 400;
}
@mixin fontWeightBold {
  font-weight: 700;
}
@mixin fontWeightBlack {
  font-weight: 900;
}

@mixin font($m, $xs, $l) {
  font-size: $m;
  min-height: $m * $l;
  line-height: $l;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    font-size: $xs;
    min-height: $xs * $l;
  }
}

// fonts
@mixin fontSmall {
  @include font(12px, 3vw, 1.6);
}
@mixin fontSmallHeading {
  @include font(12px, 3vw, 1.2);
}

@mixin fontSubMedium {
  @include font(14px, 3.5vw, 1.8);
}
@mixin fontSubMediumHeading {
  @include font(14px, 3.5vw, 1.2);
}

@mixin fontMedium {
  @include font(16px, 4.3vw, 1.8);
}
@mixin fontMediumHeading {
  @include font(16px, 4.3vw, 1.2);
}

@mixin fontBig {
  @include font(20px, 5.5vw, 1.6);
}
@mixin fontBigHeading {
  @include font(20px, 5.5vw, 1.2);
}

@mixin fontLarge {
  @include font(24px, 6.5vw, 1.6);
}
@mixin fontLargeHeading {
  @include font(24px, 6.5vw, 1.2);
}

@mixin fontHuge {
  @include font(32px, 7.5vw, 1.6);
}
@mixin fontHugeHeading {
  @include font(32px, 7.5vw, 1.2);
}

@mixin fontMassive {
  @include font(44px, 10vw, 1.6);
}
@mixin fontMassiveHeading {
  @include font(44px, 10vw, 1.2);
}
