@use "sass:math";
@import '../../../../assets/stylesheets/_utils';

.cards {
  position: relative;
  display: inline-flex;
  padding-bottom: 16px;
  min-height: 80px;

  &::after {
    width: 30px;
    flex-shrink: 0;
    content: '';
  }

  > * {
    margin-right: 16px;
    width: 80px;
  }
}

.active {
  border: 4px solid #1EC65F;
}

.avatarImage {
  border-radius: 50%;
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 12px;
}
