@use "sass:math";

@mixin transition($type:all, $duration:200ms, $delay: 0s) {
  transition-property: $type;
  transition-duration: $duration;
  transition-delay: $delay;
  transition-timing-function: $curveEaseOut;
}

@mixin animation($property) {
  -moz-animation: $property;
  -webkit-animation: $property;
  -o-animation: $property;
  -ms-animation: $property;
}

@mixin imageBackground($top: 50%, $left: 50%) {
  background-position: $top $left;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eee;
}

@mixin centerize($top:50%, $left:50%) {
  position: absolute;
  top: $top;
  left: $left;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin marginBottom($fontSize: 16px, $lineHeight: 1.4, $baseMargin: 0px) {
  margin-bottom: calc((((#{$fontSize} * #{$lineHeight}) - #{$fontSize})/2 * (-1)) + #{$baseMargin});
}

@mixin marginTop($fontSize: $fontSize, $lineHeight: $lineHeight, $baseMargin: 0px) {
  margin-top: calc((((#{$fontSize} * #{$lineHeight}) - #{$fontSize})/2 * (-1)) + #{$baseMargin});
}

@mixin flexbox($columns: 2, $margin: 8px) {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -$margin;
  margin-bottom: -$margin;

  > * {
    margin-bottom: $margin;
    margin-right: $margin;
  }

  @if $columns == 2 {
    > * {
      width: calc((50%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 3 {
    > * {
      width: calc((33.3%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 4 {
    > * {
      width: calc((25%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 5 {
    > * {
      width: calc((20%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 6 {
    > * {
      width: calc((16.6%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 7 {
    > * {
      width: calc((14.2%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 8 {
    > * {
      width: calc((12.5%) - #{$margin});
      flex-grow:  0;
      flex-basis: auto;
    }
  } @else if $columns == 1 {
    display: block;
    margin-right: 0;

    > * {
      width: inherit;
      flex-grow:  0;
      margin-right: 0;
      display: block;
    }
  } @else if $columns == 0 {
    justify-content: stretch;
    flex-wrap: nowrap;

    > * {
      flex-basis: 0;
      flex-grow:  1;
      flex-shrink: 1;
      width: auto;
    }
  }

  &.center {
    justify-content: center;
  }
}

@mixin setPlaceholderColor($c: #ddd) {
  :placeholder-shown {
    color: $c;
  }

  /* Google Chrome, Safari, Opera 15+, Android, iOS */
  ::-webkit-input-placeholder {
    color: $c;
  }

  /* Firefox 18- */
  :-moz-placeholder {
    color: $c; opacity: 1;
  }

  /* Firefox 19+ */
  ::-moz-placeholder {
    color: $c; opacity: 1;
  }

  /* IE 10+ */
  :-ms-input-placeholder {
    color: $c;
  }

  [contenteditable=true]:empty:before {
    content: attr(placeholder);
    color: $c;
    display: block; /* For Firefox */
  }
}

@mixin glissAnimationDelay($delay: 0.05, $wait: 0, $count: 10, $duration: 1000ms) {
  transition-delay: #{$delay * $count + $wait}s;
  animation-delay: #{$delay * $count + $wait}s;
  transition-duration: $duration;
  $i: 0;
  @for $i from 0 through $count {
    &:nth-child(#{$i + 1}) {
      transition-delay: #{$delay * $i + $wait}s;
      animation-delay: #{$delay * $i + $wait}s;
    }
  }
}

@mixin resetList {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

@mixin _form {

  .field {
    margin-bottom: $p;
    width: 100%;
    position: relative;

    .attention {
      font-size: 0.9em;
    }
    .sub {
      font-size: 0.9em;
      margin: math.div($p, 2) 0 $p;
      color: $colorGray;

      & + .sub {
        margin-top: math.div(-$p, 2);
      }
    }
    span {
      &.red {
        color: $colorRed;
      }
      &.gray {
        color: $colorGray;
      }
    }
  }
  .fields {
    @include flexbox(0);
    margin-bottom: math.div($p, 2);

    &.half {
      width: 50%;
    }
    &.short {
      width: 150px;
    }
  }

  .label {
    @include fontMedium;
    display: block;
    margin-bottom: math.div($p, 2);

    &.inline {
      display: inline-block;
    }
  }
  input[type=text],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=url],
  input[type=email],
  input[type=date],
  input[type=time],
  input[type=number],
  input[type=range],
  input[type=color],
  input[type=datetime],
  input[type=datetime-local],
  input[type=month],
  input[type=week],
  &__input,
  .chosen-choices,
  select,
  &__select,
  textarea,
  &__textarea {
    display: block;
    padding: math.div($p, 2);
    border: 1px solid $colorLightgray;
    border-radius: math.div($r, 2);
    box-sizing: border-box;
    min-width: 50%;
    color: $colorBlue;
    box-shadow: none;
    background: #fff;
    outline: none;

    &.fluid {
      width: 100%;
    }
    &.half {
      width: 50%;
    }
    &.short {
      width: 150px;
    }
  }
  select,
  &__select {
    cursor: pointer;
    background-size: 16px;
    padding-right: 32px;
    background-position: right 8px top 50%;
    background-repeat: no-repeat;
    min-width: inherit;

    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';

    &::-ms-expand {
      display: none;
    }
  }
  textarea,
  &__textarea {
    min-height: 120px;
    width: 100%
  }
  input[type=checkbox],
  input[type=radio] {
    cursor: pointer;
    display: inline-block;
    width: auto;

    & + label {
      display: inline-block;
    }
  }
  input[type=hidden],
  input[type=file] {
    background-color: $colorOffwhite;
    padding: $p;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    @include transition(background-color);

    @include hover() {
      background-color: $colorOffwhiteDark;
    }
  }
  input[type=submit],
  input[type=reset],
  input[type=button],
  input[type=image],
  button {
    cursor: pointer;
  }
  input[type=search] {
    padding-left: 32px;
    background-size: 14px 20px;
    background-position: 10px 6px;
    background-repeat: no-repeat;
  }

  .chosen-container, .search-field {
    width: 100% !important;
  }

  &.inline, &__inline {
    display: inline-block;
    width: auto;
  }
  &.mini, &__mini {
    padding-top: math.div($p, 4);
    padding-bottom: math.div($p, 4);
    @include fontSmall;
    background-size: 12px;
  }
  &.lightgray, &__lightgray {
    border-color: $colorLightgray;
    background-color: transparent;
  }
  .fields .field, .field.fluid {
    input[type=text],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=url],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=number],
    input[type=range],
    input[type=color],
    input[type=datetime],
    input[type=datetime-local],
    input[type=month],
    input[type=week],
    &__input,
    .chosen-choices,
    select,
    &__select,
    textarea,
    &__textarea {
      width: 100%;
    }
  }
  .field.half {
    input[type=text],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=url],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=number],
    input[type=range],
    input[type=color],
    input[type=datetime],
    input[type=datetime-local],
    input[type=month],
    input[type=week],
    &__input,
    .chosen-choices,
    select,
    &__select,
    textarea,
    &__textarea {
      width: 50%;
    }
  }
  .field.short {
    input[type=text],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=url],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=number],
    input[type=range],
    input[type=color],
    input[type=datetime],
    input[type=datetime-local],
    input[type=month],
    input[type=week],
    &__input,
    .chosen-choices,
    select,
    &__select,
    textarea,
    &__textarea {
      width: 150px;
      min-width: 0;
    }
  }

  .collapse {
    margin-right: 0;

    input,
    .chosen-choices,
    select,
    textarea {
      border-radius: 0;
      border-right: 1px dashed $colorLightgray;
    }
    .field {
      margin-right: 0;
      &:first-child {
        input,
        select,
        textarea {
          border-radius: 4px 0 0 4px;
        }
      }
      &:last-child {
        input,
        .chosen-choices,
        select,
        textarea {
          border-radius: 0 4px 4px 0;
          border-left: none;
          border-right: 1px solid $colorLightgray;
        }
      }
    }
  }

  .subdomain {
    position: relative;
    display: block;

    input {
      padding-left: 56px;
      padding-right: 100px;
    }

    &:before {
      content: 'https://';
      position: absolute;
      left: 8px;
      color: $colorGray;
      top: 8px;
    }
    &:after {
      content: '.aboutus.tv';
      position: absolute;
      right: 8px;
      color: $colorGray;
      top: 8px;
    }
  }
  .twitter {
    position: relative;
    display: block;

    input {
      padding-left: 24px;
    }

    &:before {
      content: '@';
      position: absolute;
      left: 8px;
      color: $colorGray;
      top: 8px;
    }
  }
  .colors {
    padding: math.div($p, 2) 0 $p;
    li {
      display: inline-block;
      list-style: none;

      button {
        width: 20px;
        height: 20px;
        text-indent: -9999px;
        border-radius: 10px;
        padding: 0;
      }
    }
  }
  .suggests {
    padding: math.div($p, 2) 0 $p;
    text-align: left;
    li {
      display: inline-block;
      list-style: none;

      button {
        height: 22px;
        border-radius: 2px;
        padding: 0 math.div($p, 2) 0;
        margin-bottom: math.div($p, 4);
      }
    }
  }
  .switch {
    input {
      position: absolute;
      left: -9999px;

      &:checked + {
        .yes {
          background-color: $colorBlue;

          & ~ .no {
            background-color: $colorLightgray !important;
          }
        }
      }

    }
    span {
      padding: math.div($p, 2) $p3;
      background-color: $colorLightgray;
      color: #fff;
      display: inline-block;
      @include transition(all);

      $tr: 4px;

      &.yes {
        border-radius: $tr 0 0 $tr;
      }

      &.no {
        border-radius: 0 $tr $tr 0;
        margin-left: 2px;
        background-color: $colorRed;
      }
    }
  }
  .thumb {
    position: absolute;
    right: 3px;
    bottom: 3px;
    top: 30px;
    width: 15%;
    max-width: 100px;
    height: auto;
    border: 1px solid $colorLightgray;
    @include imageBackground;
    background-size: contain;
    background-color: #fff;
    border-radius: 4px
  }
  .checkboxes, .radiobuttons {
    margin: 0 0 $p;

    label {
      display: inline-block;
      margin-right: math.div($p, 2);
      padding: 4px math.div($p, 2);
      border-radius: 4px;
      background-color: $colorOffwhite;
    }
  }
}

@mixin _markdown($margin: $p, $extra_margin: $p) {
  > * {
    margin-bottom: $margin;

    &:last-child {
      margin-bottom: 0;
    }
  }
  * + h1 {
    margin-top: $margin + $extra_margin + $extra_margin;
  }
  * + h2, * + h3 {
    margin-top: $margin + $extra_margin;
  }
  h1, h2, h3 {
    margin-bottom: $margin;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
  }
  img, iframe {
    max-width: 100%;
  }
  blockquote {
    background-color: $colorOffwhite;
    padding: $p2;
    margin: ($margin + $extra_margin) 0;
    font-style: italic;
    margin-top: $p !important;
    border-left: none !important;
    margin-bottom: $p !important;
  }
  pre {
    margin-top: $p !important;
    margin-bottom: $p !important;
    color: #fff;
    background-color: $colorBlack;
    padding: $p2;
  }
  p, blockquote {
    margin-bottom: $margin;
    line-height: 1.7;

    &:last-child {
      margin-bottom: 0;
    }
  }
  ul, ol {
    margin-bottom: $margin + $extra_margin;
    padding-left: $p2;

    li {
      padding: 4px 0 4px math.div($margin, 2);
    }

    ul, ol {
      // margin-top: $margin + $extra_margin;
      margin-bottom: 0;
    }
  }
  ul {
    li {
      list-style: disc;
    }
  }
  ol {
    li {
      list-style: decimal;

      ol {
        margin-top: math.div($margin, 2);
        li {
          list-style: decimal;

          ol {
            li {
              list-style: decimal;
            }
          }
        }
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  hr {
    border: none;
    border-top: 1px dashed $colorStrokeContent;
  }
  p {
    line-height: 1.7;
  }
  blockquote {
    background-color: $colorFillUI;
    padding: $p2;
    margin: ($margin + $extra_margin) 0;
    font-style: italic;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }

}

@mixin _grid() {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &.padded > * {
    padding: 0 $p;
  }
  &.padded__thin > * {
    padding: 0 math.div($p, 2);
  }

  $g: 8.3%;

  .col-0 {
    width: 4%;
  }
  @for $i from 1 through 12 {
    .col-#{$i} {
      width: $g * $i;
      display: block;
      &.border {
        border-right: 1px solid $colorLightgray;
      }
    }
  }
}

@mixin cf {
  zoom:1;

  &:before,
  &:after {
    content:"";
    display:table;
  }
  &:after {
    clear:both;
  }
}

@mixin hover {
  /* SM over(PC only) */
  @media screen and (min-width: $screen-md-min) {
    &:hover {
      @content;
    }
  }
}
