@import '../assets/stylesheets/_utils';

.banner {
  display: flex;
  margin-top: -$p4;
  margin-bottom: $p4;
  background-color: $colorNavy;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    margin-top: -$p2;
    display: block;
    margin-bottom: $p2;
  }

  .video {
    position: relative;
    overflow: hidden;
    width: 100%;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      height: 40vh;
      max-height: 100vw;
      margin-bottom: 0;
    }

    iframe {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
  .copy {
    width: 100%;
    color: #fff;
    padding: $p4;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      padding: $p2 $p;
    }

    h2 {
      @include fontMassiveHeading;
      @include fontWeightBold;
      margin-bottom: $p;
      @include kern;
      letter-spacing: 0.06em;
    }
    p {
      @include fontMedium;
      margin-bottom: $p;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    .login {
      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        a {
          display: block;
        }
      };
    }
    .downloads {
      margin-top: $p2;
      white-space: nowrap;
      overflow: hidden;

      /* XS under */
      @media screen and (max-width: $screen-xs-max) {
        text-align: center;
      }

      a {
        margin-right: 8px;
      }
    }
  }
}

.whats {
  @include flexbox(3,$p);

  /* MD under */
  @media screen and (max-width: $screen-md-max) {
    @include flexbox(2, $p);
  }
  /* SM under */
  @media screen and (max-width: $screen-sm-max) {
    @include flexbox(1);
  }
  .what {
    display: block;
    padding: $p2;
    background-color: $colorFillUI;
    border-radius: $r2;
    max-width: 400px;

    /* MD under */
    @media screen and (max-width: $screen-md-max) {
      max-width: inherit;
    }
    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      padding: $p2 $p;
    }

    &_title {
      @include fontLargeHeading;
      @include fontWeightBold;
      margin-bottom: $p;
    }
    &_description {
      @include fontMedium;
      margin-bottom: $p;
    }
    &_sub {
      @include fontSmall;
      color: $colorFillSubContentText;
    }
    &_images {
      margin-top: $p2;
      @include flexbox(2);

      img {
        max-width: 150px;
      }
    }
  }
}

.vision {
  p {
    @include fontLarge;
    @include fontWeightBold;
    margin-bottom: $p;
  }
}

.moreLink {
  margin-left: 48px;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: $colorFillLinkText;
  transform: translateY(-6px);
  display: inline-block;

  @include hover() {
    color: $colorFillSubContentText;
  }
}

.maxWidth {
  max-width: 1164px;
  margin: 0 auto;
  padding: 0 32px;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    padding: 0 16px;
  }
}

.pageTitle {
  margin-bottom: $p3;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: $p2;
  }
}
