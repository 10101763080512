@use "sass:math";
@import '../../../../assets/stylesheets/_utils';

.cards {
  position: relative;
  display: inline-flex;
  padding-bottom: 16px;

  &::after {
    width: 30px;
    flex-shrink: 0;
    content: '';
  }

  > * {
    margin-right: 8px;
    text-decoration: none !important;
  }
}
