@use "sass:math";
@import '../../../../assets/stylesheets/_utils';

.cards {
  position: relative;
  display: flex;
  padding-bottom: 16px;

  &::after {
    width: 30px;
    flex-shrink: 0;
    content: '';
  }
}

.wrap {
  flex-wrap: wrap;
}
