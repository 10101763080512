// values
$p: 16px;
$p2: $p * 2;
$p3: $p * 3;
$p4: $p * 4;
$p5: $p * 5;
$p10: $p * 10;
$r: 8px;
$r2: $r * 2;
$r5: $r * 5;

// curves
$curveBack: cubic-bezier(0.000, 1.030, 0.270, 1.230);
$curveEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$curve: cubic-bezier(0.165, 0.840, 0.440, 1.000);

// colors
$colorBlue: #0033ff;
$colorBlack: #222;
$colorDark: #111;
$colorNavy: #09142B;
$colorGray: #999;
$colorDarkGray: #333;
$colorLightgray: #DDDDDD;
$colorOffwhite: #F2F4F7;
$colorOffwhiteDark: #E2E4E7;
$colorRed: #FF4444;
$colorGreen: #1EC65F;
$colorYellow: #FFD90F;
$colorWhite: #ffffff;
$colorOrange: #FFA113;
$colorPurple: #4111FF;
$colorMagenta: #FF00FF;
$colorTwitter: #1DA1F2;

// Definitions
$colorBackgroundOrganizer: #eee;
$colorBackgroundAudience: $colorWhite;
$colorBackgroundDark: $colorDarkGray;

$colorFillContentText: $colorBlack;
$colorFillSubContentText: rgba($colorBlack, 0.4);
$colorFillLinkText: $colorRed;

$colorFillContent: rgba($colorLightgray, 0.3);
$colorFillUI: rgba($colorLightgray, 0.3);
$colorFillUIMore: rgba($colorLightgray, 0.6);

$colorStrokeContent: rgba($colorBlack, 0.1);

// Sizes
$navWidth: 280px;
$windowMarginLeftPC: $navWidth;
$windowMarginRightPC: max(15vw,100px);
$windowNegativeMarginLeftPC: -$navWidth;
$windowNegativeMarginRightPC: min(-15vw,-100px);
$NoticeHeightPC: 50px;