@import '../../../assets/stylesheets/_utils';

.classroomsWrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 32px;

    /* XS under */
    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: $p;
    }
  }
}

.classrooms {
  position: relative;
  padding-left: calc((100% - 1164px) / 2 + 32px);
  padding-bottom: $p;

  @media screen and (max-width: 1164px) {
    padding-left: 32px;
  }

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    padding-left: 16px;
  }
}

.maxWidth {
  max-width: 1164px;
  margin: 0 auto;
  padding: 0 32px;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    padding: 0 16px;
  }
}

.wrap {
  > * {
    flex-wrap: wrap;
  }
}
