@use "sass:math";
@import "../../../../assets/stylesheets/_utils";

.card {
  display: block;
  position: relative;
  text-decoration: none;
  width: 240px;
  margin-right: 30px;
  flex-shrink: 0;
  margin-bottom: 16px;

  /* XS under */
  @media screen and (max-width: $screen-xs-max) {
    margin-right: 20px;
  }

  &:hover {
    &__thumbnail {
      filter: brightness(1.1);
    }
  }

  &__inner {
    position: relative;
    height: 160px;
  }

  &__thumbnail {
    position: relative;
    height: 100%;
    margin-bottom: 8px;
    @include imageBackground;
    border-radius: 15px;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property: filter;
    object-fit: cover;
  }

  &__name {
    @include fontWeightBold;
    letter-spacing: 0;
    margin-top: 8px;
    margin-bottom: 0 !important;
  }

  &__count {
    overflow: hidden;
    color: $colorFillSubContentText;

    p {
      margin-top: 8px;
      @include fontSmall;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
